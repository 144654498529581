import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { AuthorisationFacade } from '~/app/core/state/authorisation/authorisation.facade';
import { ButtonComponent } from '~/app/shared/components/button/button.component';
import { IconComponent } from '~/app/shared/components/icon/icon.component';
import { LabelComponent } from '~/app/shared/components/label/label.component';
import { ModalComponent } from '~/app/shared/components/modal/modal.component';
import { ModalRef } from '~/app/shared/services/modal/modal-ref';
import { User } from '~/app/shared/types/user/user.type';


@Component({
    selector: 'eb-modal-onboarding-trial-welcome',
    templateUrl: './modal-onboarding-trial-welcome.component.html',
    styleUrls: ['./modal-onboarding-trial-welcome.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LabelComponent,
        IconComponent,
        ButtonComponent,
        DatePipe,
        TranslocoPipe,
    ],
})
export class ModalOnboardingTrialWelcomeComponent {
    public user: User | undefined = this.authFacade.getUserSnapshot();

    public expirationDate: string | undefined = this.authorizationFacade.getEndValiditySnapshot();

    public constructor(
        public authFacade: AuthenticationFacade,
        public authorizationFacade: AuthorisationFacade,
        public modal: ModalComponent,
        public modalRef: ModalRef,
    ) {
        this.modal?.setEnableBackdropClick(false);
    }

    public close() {
        this.modalRef.close({});
    }
}
