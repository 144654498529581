/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Envestboard API
 * ## Implementation details

 * Every string passed to and from the API must be UTF-8 encoded
 * Date and Datetime are formatted according to standard ISO-8601
 * Default timezone is UTC
 * Currency code are formatted according to standard ISO-4217
 * Language code are formatted according to standard ISO-639-1
 * Country code are formatted according to standard ISO-3166 alpha-3
 * Zone and continent are formatted with a custom 3-10 characters code
 * Unless specified otherwise, all API methods require authentication

## Authentication and authorization

**Authentication** is available from the specif endpoint. With the correct credentials, you get an access token with a very short lifetime and a refresh token used to recreate the access token when it expires. The refresh token can only be used once.<br />
**Authorization** is based on the jwt token in the header obtained from the authentication process when you call the REST APIs.

## Pagination

We uses keyset (cursor) pagination to limit the response size for resources that return a potentially large collection of items.
To browse and retrieve the different result pages, you must provide the last id of the previous page in the `startAt` parameter or field in the request.
A request to a paged API will result in a values array wrapped in a JSON object with some paging metadata.

Example :
```
{
  "values": [
    ...
  ],
  "number": 0,
  "size": 0,
  "totalNumber": 0,
  "startAt": 0,
}
```

 * OpenAPI spec version: 1.8.1-SNAPSHOT
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpHeaders,
  HttpParams,
  HttpContext
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  UserInfoModel,
  UserInfoUpdateRequestModel,
  UpdatePasswordModel,
  MemberRegisterModel,
  RefreshTokenResponseModel,
  RefreshTokenRequestModel,
  CreatePasswordModel,
  ResetPasswordModel,
  VerifyPhoneOtpRequestModel,
  TrialRequestModel,
  SendPhoneOtpResponseModel,
  SendPhoneOtpRequestModel,
  SalesRecallRequestModel,
  ChallengeResponseModel,
  ChallengeRequestModel,
  MemberAuthenticateResponseModel,
  AuthenticateMemberModel,
  MemberRegisterActivationModel,
  UseCaseModel,
  PermissionModel,
  LicenceModel,
  CompanyModel,
  JobModel
} from '../../../../shared/types/envestboard-api'


type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class MemberService {
  constructor(
    private http: HttpClient,
  ) {}/**
 * Get Member by ID
 * @summary  Get Member by ID
 */
 getApiV1MembersGet<TData = UserInfoModel>(
    id: number, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/members/${id}`,options
    );
  }
/**
 * Deprecated !
Use PATCH /api/v1/contacts/{contactId} instead
 * @deprecated
 * @summary Update Member by ID
 */
 putApiV1MembersId<TData = UserInfoUpdateRequestModel>(
    id: number,
    userInfoUpdateRequestModel: UserInfoUpdateRequestModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `/members/${id}`,
      userInfoUpdateRequestModel,options
    );
  }
/**
 * Update password
 * @summary Update a Member password
 */
 putApiV1MembersIdPassword<TData = void>(
    id: number,
    updatePasswordModel: UpdatePasswordModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `/members/${id}/password`,
      updatePasswordModel,options
    );
  }
/**
 * Register a new member
 * @summary Register new Member
 */
 postApiV1RegisterMember<TData = MemberRegisterModel>(
    memberRegisterModel: MemberRegisterModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/members/registration`,
      memberRegisterModel,options
    );
  }
/**
 * Refresh token
 * @summary Refresh the API access token from valid refresh token
 */
 getApiV1MembersRefreshToken<TData = RefreshTokenResponseModel>(
    refreshTokenRequestModel: RefreshTokenRequestModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/members/refreshToken`,
      refreshTokenRequestModel,options
    );
  }
/**
 * Create new password
 * @summary Create new password after reset
 */
 postApiV1MembersCreatePassword<TData = void>(
    createPasswordModel: CreatePasswordModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/members/password`,
      createPasswordModel,options
    );
  }
/**
 * Reset the Member password
 * @summary  Reset the Member password
 */
 deleteApiV1MembersPassword<TData = void>(
    resetPasswordModel: ResetPasswordModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `/members/password`,{body:
      resetPasswordModel, ...options}
    );
  }
/**
 * Verify OTP code and validate the new member phone
 * @summary  Verify OTP code and validate the new member phone
 */
 postApiV1MembersMeVerifyPhoneOtp<TData = void>(
    verifyPhoneOtpRequestModel: VerifyPhoneOtpRequestModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/members/me/verify-phone-otp`,
      verifyPhoneOtpRequestModel,options
    );
  }
/**
 * Enable a free trial for the current member
 * @summary  Enable a free trial for the current member
 */
 postApiV1MembersMeTrial<TData = void>(
    trialRequestModel: TrialRequestModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/members/me/trial`,
      trialRequestModel,options
    );
  }
/**
 * Request a phone change and send an OTP sms to verify it
 * @summary  Request a phone change and send an OTP sms to verify it
 */
 postApiV1MembersMeSendPhoneOtp<TData = SendPhoneOtpResponseModel[]>(
    sendPhoneOtpRequestModel: SendPhoneOtpRequestModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/members/me/send-phone-otp`,
      sendPhoneOtpRequestModel,options
    );
  }
/**
 * Send a sales recall request for the current member
 * @summary  Send a sales recall request for the current member
 */
 postApiV1MembersMeSalesRecall<TData = void>(
    salesRecallRequestModel: SalesRecallRequestModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/members/me/sales-recall`,
      salesRecallRequestModel,options
    );
  }
/**
 * Retrieve a specific token from challenge. 
It cannot be use as authentication token but only for security purpose in "Challenge" header. 

 * @summary Retrieve a specific token from challenge
 */
 postApiV1MembersChallenge<TData = ChallengeResponseModel>(
    challengeRequestModel: ChallengeRequestModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/members/challenge`,
      challengeRequestModel,options
    );
  }
/**
 * Authenticate a member
 * @summary Authenticate a member
 */
 postApiV1MembersAuthentication<TData = MemberAuthenticateResponseModel>(
    authenticateMemberModel: AuthenticateMemberModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/members/authentication`,
      authenticateMemberModel,options
    );
  }
/**
 * Member activation end point to validate the email and complete the member information
 * @summary Member activation
 */
 getApiV1MembersActivation<TData = MemberAuthenticateResponseModel>(
    memberRegisterActivationModel: MemberRegisterActivationModel, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/members/activation`,
      memberRegisterActivationModel,options
    );
  }
/**
 * Retrieve the list of use cases.
 * @summary Retrieve the list of use cases
 */
 getApiV1MembersUseCases<TData = UseCaseModel[]>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/members/use-cases`,options
    );
  }
/**
 * Get current authenticate member information
 * @summary  Get current authenticate member information
 */
 getApiV1MembersMeInfo<TData = UserInfoModel>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/members/me`,options
    );
  }
/**
 * Get current authenticate member permissions
 * @summary  Get current authenticate member permissions
 */
 getApiV1MembersMePermissions<TData = PermissionModel[]>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/members/me/permissions`,options
    );
  }
/**
 * Get current authenticate member licence
 * @summary  Get current authenticate member licence
 */
 getApiV1MembersMeLicence<TData = LicenceModel>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/members/me/licence`,options
    );
  }
/**
 * Get company information of the authenticate member
 * @summary  Get company of the authenticate member
 */
 getApiV1MembersMeCompany<TData = CompanyModel>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/members/me/company`,options
    );
  }
/**
 * Retrieve the list of jobs.
 * @summary Retrieve the list of jobs
 */
 getApiV1MembersJobs<TData = JobModel[]>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/members/jobs`,options
    );
  }
};

