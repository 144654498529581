<div class="modal-onboarding-trial-welcome__container align-items-center">
    <div class="modal-onboarding-trial-welcome__left">
        <div class="modal-onboarding-trial-welcome__congratulations">
            {{ 'authorisation.welcome_freetrial' | transloco }}
        </div>
        <div class="modal-onboarding-trial-welcome__body">
            <eb-label
                class="modal-onboarding-trial-welcome__body-text"
                [innerHTML]="'authorisation.info_freetrial' | transloco"
                size="lg"
                weight="bold"
            />
            <eb-label
                class="modal-onboarding-trial-welcome__body-text"
                [innerHTML]="'authorisation.info_freetrial_part2' | transloco"
                size="lg"
            />
            <eb-label
                class="modal-onboarding-trial-welcome__body-text"
                [innerHTML]="'authorisation.info_freetrial_part3' | transloco"
                size="lg"
            />
            <eb-label
                class="modal-onboarding-trial-welcome__body-text"
                [innerHTML]="'authorisation.info_freetrial_part4' | transloco"
                size="lg"
                weight="bold"
            />
        </div>
        <eb-label
            class="modal-onboarding-trial-welcome__footer"
            [innerHTML]="'authorisation.expiration_freetrial' | transloco: { date: (expirationDate | date: 'shortDate') }"
        />
    </div>
    <div class="modal-onboarding-trial-welcome__right">
        <eb-icon
            [width]="85"
            icon="ui/common/illustration-welcome-trial"
        />
    </div>
</div>
<div class="modal-onboarding-trial-welcome__buttons">
    <button
        eb-button
        (click)="close()"
    >
        <eb-label
            size="lg"
            weight="bold"
        >
            {{ 'common.start' | transloco }}
        </eb-label>
    </button>
</div>
