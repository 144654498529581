<div class="contact-confirmation">
    <div class="contact-confirmation__icon">
        <eb-icon icon="ui/action/symbols/check-broken" />
    </div>
    <eb-label
        class="contact-confirmation__text"
        size="lg"
        weight="bold"
    >
        {{ 'try_free_trial.ask_sales_success' | transloco }}
    </eb-label>
</div>
