import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';


import { AlertComponent } from '~/app/shared/components/alert/alert.component';
import { ButtonLinkComponent } from '~/app/shared/components/button-link/button-link.component';
import { ButtonWithIconAltComponent } from '~/app/shared/components/button-with-icon-alt/button-with-icon-alt.component';
import { H2Component } from '~/app/shared/components/h2/h2.component';
import { IconComponent } from '~/app/shared/components/icon/icon.component';
import { LabelComponent } from '~/app/shared/components/label/label.component';
import { ModalRef } from '~/app/shared/services/modal/modal-ref';
import { ResumeFeatureContext } from '~/app/shared/types/resume-feature-context.type';

export enum ModalLockedFeatureMode {
    UPGRADE_TO_FREEMIUM = 0,
    UPGRADE_TO_FREE_TRIAL = 1,
    UPGRADE_TO_FREE_TRIAL_OR_PLAN = 2,
    UPGRADE_PLAN = 3,
}

export type ModalLockedFeatureInputArgs = {
    withContext: boolean,
    context: ResumeFeatureContext | undefined,
    mode: ModalLockedFeatureMode,
}
export type ModalLockedFeatureCallbackAction = 'SIGN_UP' | 'SIGN_IN' | 'UPGRADE_PLAN' | 'UPGRADE_FREE_TRIAL';

@Component({
    selector: 'eb-modal-locked-feature',
    templateUrl: './modal-locked-feature.component.html',
    styleUrls: ['./modal-locked-feature.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        H2Component,
        LabelComponent,
        AlertComponent,
        IconComponent,
        ButtonWithIconAltComponent,
        ButtonLinkComponent,
        TranslocoPipe,
    ],
})
export class ModalLockedFeatureComponent {
    @HostBinding('class.no-context')
    public noContext: boolean = !this.ref.data.withContext;

    public ModalLockedFeatureMode = ModalLockedFeatureMode;

    constructor(
        public ref: ModalRef<ModalLockedFeatureCallbackAction, ModalLockedFeatureInputArgs>,
    ) {
    }

    signIn() {
        this.ref.close('SIGN_IN');
    }

    signUp() {
        this.ref.close('SIGN_UP');
    }

    upgradePlan() {
        this.ref.close('UPGRADE_PLAN');
    }

    freeTrial() {
        this.ref.close('UPGRADE_FREE_TRIAL');
    }
}
