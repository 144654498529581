import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import {
    LabelComponent,
    LabelComponentSize,
    LabelComponentTextAlign,
    LabelComponentTextTransform,
    LabelComponentWeight,
} from '~/app/shared/components/label/label.component';

import { IconComponent } from '../icon/icon.component';

export type ButtonComponentWidth = 'auto' | 'full';
export type ButtonComponentType = 'submit' | 'reset' | 'button' | 'menu';

@Component({
    selector: 'eb-button-with-icon-alt',
    templateUrl: './button-with-icon-alt.component.html',
    styleUrls: ['./button-with-icon-alt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [LabelComponent, IconComponent],
})
export class ButtonWithIconAltComponent {
    @Input()
    public icon: string = '';

    @Input()
    public iconWidth: number = 32;

    @Input()
    public width: ButtonComponentWidth = 'auto';

    @Input()
    public type: ButtonComponentType = 'submit';

    @Input()
    public disabled = false;

    @Input()
    public isLoading = false;

    @Input()
    public labelTextTransform: LabelComponentTextTransform = 'uppercase';

    @Input()
    public labelWeight: LabelComponentWeight = 'bold';

    @Input()
    public labelSize: LabelComponentSize = 'lg';

    @Input()
    public labelTextAlign: LabelComponentTextAlign = 'center';

    @Output()
    public buttonClick = new EventEmitter();

    public onButtonClick() {
        this.buttonClick.emit();
    }
}
