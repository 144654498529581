import { AsyncPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import {
    delay,
    Observable,
    tap,
} from 'rxjs';

import { MemberService } from '~/app/core/services/envestboard-api/member/member.service';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { ModalRef } from '~/app/shared/services/modal/modal-ref';
import {
    JobModel,
    TrialRequestModel,
    UseCaseModel,
} from '~/app/shared/types/envestboard-api';

import { ModalOnboardingContactConfirmationComponent } from '../../presentation/modal-onboarding-contact-confirmation/modal-onboarding-contact-confirmation.component';
import { ModalOnboardingContactFormComponent } from '../../presentation/modal-onboarding-contact-form/modal-onboarding-contact-form.component';

export type ModalOnboardingContactSalesInput = {
    feature?: string,
}

@UntilDestroy()
@Component({
    selector: 'eb-modal-onboarding-contact-sales',
    templateUrl: './modal-onboarding-contact-sales.component.html',
    styleUrls: ['./modal-onboarding-contact-sales.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalOnboardingContactFormComponent,
        ModalOnboardingContactConfirmationComponent,
        AsyncPipe,
        TranslocoPipe,
    ],
})
export class ModalOnboardingContactSalesComponent {
    public jobsOptions$: Observable<JobModel> = this.membersService.getApiV1MembersJobs();

    public useCasesOptions$: Observable<UseCaseModel[]> = this.membersService.getApiV1MembersUseCases<UseCaseModel[]>();

    public user = this.authenticationFacade.getUserSnapshot();

    public isSalesRecallSent: boolean = false;

    constructor(
        private modalRef: ModalRef<{}, ModalOnboardingContactSalesInput>,
        private cd: ChangeDetectorRef,
        private membersService: MemberService,
        private authenticationFacade: AuthenticationFacade,
    ) {
    }

    public confirm(data: TrialRequestModel) {
        this.membersService.postApiV1MembersMeSalesRecall({
            ...data,
            feature: this.modalRef.data.feature,
        })
            .pipe(
                tap(() => {
                    this.isSalesRecallSent = true;
                    this.cd.markForCheck();
                }),
                delay(4000),
                tap(() => {
                    this.modalRef.close({});
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }
}
