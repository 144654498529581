<button
    class="button-with-icon"
    [attr.disabled]="disabled ? 'disabled' : null"
    [type]="type"
    (click)="onButtonClick()"
>
    <label class="button-with-icon__wrapper">
        <eb-label
            class="button-with-icon__label"
            [size]="labelSize"
            [textAlign]="labelTextAlign"
            [textTransform]="labelTextTransform"
            [weight]="labelWeight"
        >
            <ng-content />
            @if (isLoading) {
                <svg
                    class="button-with-icon__spinner"
                    viewBox="0 0 50 50"
                >
                    <circle
                        class="button-with-icon__spinner-path"
                        cx="25"
                        cy="25"
                        fill="none"
                        r="20"
                        stroke-width="5"
                    />
                </svg>
            }
        </eb-label>
        <eb-icon
            class="button-with-icon__icon"
            [style.padding-bottom]="(40 - iconWidth) / 2 + 'px'"
            [style.padding-top]="(40 - iconWidth) / 2 + 'px'"
            [icon]="icon"
            [width]="iconWidth"
        />
    </label>
</button>
