import { AsyncPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import {
    Observable,
    switchMap,
    tap,
} from 'rxjs';

import { MemberService } from '~/app/core/services/envestboard-api/member/member.service';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { AuthorisationFacade } from '~/app/core/state/authorisation/authorisation.facade';
import { ModalRef } from '~/app/shared/services/modal/modal-ref';
import {
    JobModel,
    TrialRequestModel,
    UseCaseModel,
} from '~/app/shared/types/envestboard-api';

import { ModalOnboardingContactFormComponent } from '../../presentation/modal-onboarding-contact-form/modal-onboarding-contact-form.component';

@UntilDestroy()
@Component({
    selector: 'eb-modal-onboarding-subscribe-to-free-trial',
    templateUrl: './modal-onboarding-subscribe-to-free-trial.component.html',
    styleUrls: ['./modal-onboarding-subscribe-to-free-trial.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalOnboardingContactFormComponent,
        AsyncPipe,
        TranslocoPipe,
    ],
})
export class ModalOnboardingSubscribeToFreeTrialComponent {
    public jobsOptions$: Observable<JobModel> = this.membersService.getApiV1MembersJobs();

    public useCasesOptions$: Observable<UseCaseModel[]> = this.membersService.getApiV1MembersUseCases<UseCaseModel[]>();

    public user = this.authenticationFacade.getUserSnapshot();

    constructor(
        private modalRef: ModalRef,
        private authenticationFacade: AuthenticationFacade,
        private authorisationFacade: AuthorisationFacade,
        private membersService: MemberService,
    ) {
    }

    public refreshToken(): Observable<unknown> {
        const refreshToken = this.authenticationFacade.getRefreshTokenSnapshot();

        if (!refreshToken) {
            return this.authenticationFacade.logout();
        }

        this.authenticationFacade.refreshToken({ refreshToken });

        return this.authenticationFacade.onRefreshTokenSuccess();
    }

    public confirm(data: TrialRequestModel) {
        this.membersService.postApiV1MembersMeTrial(data)
            .pipe(
                switchMap(() => this.refreshToken()),
                switchMap(() => {
                    this.authorisationFacade.getAuthorisations(true);
                    return this.authorisationFacade.onGetAuthorisationSuccess();
                }),
                tap(() => {
                    this.modalRef.close(true);
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }
}
