<div
    class="material-input-textarea__group"
    [class.material-input-textarea__group--editable]="editOnClickActive"
    [class.material-input-textarea__group--has-value]="fcValue.value || inputFocused || labelPosition === 'top'"
    [class.material-input-textarea__group--readonly]="!canEdit"
>
    @if (label) {
        <div class="material-input-textarea__label"
            >{{ label }}
            @if (required) {
                <span class="material-input-textarea__label-required">*</span>
            }
        </div>
    }

    <textarea
        #input
        class="material-input-textarea__input"
        [formControl]="fcValue"
        [placeholder]="placeholder"
        [readonly]="!canEdit ? 'readonly' : ''"
        [rows]="rows"
        (focus)="focusInput(true)"
        (focusout)="focusInput(false)"
    ></textarea>

    <div class="material-input-textarea__buttons">
        @if (editOnClickActive && !canEdit) {
            <div class="material-input-textarea__edit-button-wrapper">
                <eb-icon
                    class="material-input-textarea__edit-button"
                    [width]="15"
                    (click)="changeEditMode(true)"
                    icon="ui/action/symbols/edit"
                />
            </div>
        }
    </div>
    <div class="material-input-textarea__bar"></div>
</div>
