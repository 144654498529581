@if (!isSalesRecallSent) {
    <eb-modal-onboarding-contact-form
        [jobs]="jobsOptions$ | async"
        [phone]="user?.phone"
        [phoneVerified]="user?.phoneVerified ?? false"
        [title]="'authorisation.contact_form.upgrade_plan_title' | transloco"
        [useCases]="useCasesOptions$ | async"
        (confirm)="confirm($event)"
    />
} @else {
    <eb-modal-onboarding-contact-confirmation />
}
