import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

import { IconComponent } from '~/app/shared/components/icon/icon.component';
import { LabelComponent } from '~/app/shared/components/label/label.component';

@Component({
    selector: 'eb-modal-onboarding-contact-confirmation',
    templateUrl: './modal-onboarding-contact-confirmation.component.html',
    styleUrls: ['./modal-onboarding-contact-confirmation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        IconComponent,
        LabelComponent,
        TranslocoPipe,
    ],
})
export class ModalOnboardingContactConfirmationComponent {

}
