import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';


import { ButtonComponent } from '~/app/shared/components/button/button.component';
import { CheckboxInputComponent } from '~/app/shared/components/checkbox-input/checkbox-input.component';
import { LabelComponent } from '~/app/shared/components/label/label.component';
import { MaterialInputPhoneNumberComponent } from '~/app/shared/components/material-input-phone-number/material-input-phone-number.component';
import { MaterialInputSelectComponent } from '~/app/shared/components/material-input-select/material-input-select.component';
import { MaterialInputSelectOptionComponent } from '~/app/shared/components/material-input-select-option/material-input-select-option.component';
import { MaterialInputTextareaComponent } from '~/app/shared/components/material-input-textarea/material-input-textarea.component';
import {
    JobModel,
    TrialRequestModel,
    UseCaseModel,
} from '~/app/shared/types/envestboard-api';

@Component({
    selector: 'eb-modal-onboarding-contact-form',
    templateUrl: './modal-onboarding-contact-form.component.html',
    styleUrls: ['./modal-onboarding-contact-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LabelComponent,
        FormsModule,
        ReactiveFormsModule,
        MaterialInputSelectComponent,
        MaterialInputSelectOptionComponent,
        MaterialInputTextareaComponent,
        MaterialInputPhoneNumberComponent,
        CheckboxInputComponent,
        ButtonComponent,
        TranslocoPipe,
    ],
})
export class ModalOnboardingContactFormComponent {
    @Input()
    public title = '';

    @Input()
    public subtitle = '';

    @Input()
    public set phone(value: string) {
        this.form.controls.phone.setValue(value, { emitEvent: false });
    }

    @Input()
    public set phoneVerified(value: boolean) {
        if (value) {
            this.form.controls.phone.disable({ emitEvent: false });
        } else {
            this.form.controls.phone.enable({ emitEvent: false });
        }
    }

    @Input()
    public jobs: JobModel[] = [];

    @Input()
    public useCases: UseCaseModel[] = [];

    @Output()
    public confirm = new EventEmitter<TrialRequestModel>();

    public form = new FormGroup({
        jobId: new FormControl<number | null>(null, {
            validators: [Validators.required],
            nonNullable: true,
        }),
        useCaseId: new FormControl<number | null>(null, {
            validators: [Validators.required],
            nonNullable: true,
        }),
        phone: new FormControl<string>(
            {
                value: this.phone,
                disabled: this.phoneVerified,
            },
            {
                validators: [Validators.required],
                nonNullable: true,
            },
        ),
        description: new FormControl<string>('', {
            validators: [],
            nonNullable: true,
        }),
        allowSalesRecall: new FormControl<boolean>(true, {
            validators: [],
            nonNullable: true,
        }),
    });

    public submitForm() {
        if (this.form.invalid) {
            return;
        }

        const formValue: TrialRequestModel = this.form.getRawValue() as TrialRequestModel;
        this.confirm.emit(formValue);
    }
}
