import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormsModule,
    NgControl,
    ReactiveFormsModule,
    UntypedFormControl,
} from '@angular/forms';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import {
    filter,
    Subject,
} from 'rxjs';
import {
    debounceTime,
    startWith,
} from 'rxjs/operators';

import { IconComponent } from '../icon/icon.component';

@UntilDestroy()
@Component({
    selector: 'eb-material-input-textarea',
    templateUrl: './material-input-textarea.component.html',
    styleUrls: ['./material-input-textarea.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        IconComponent,
    ],
})
export class MaterialInputTextareaComponent implements ControlValueAccessor, AfterViewInit {
    @Input()
    public placeholder: string = '';

    @Input()
    public label: string = '';

    @Input()
    public labelPosition: 'top' | 'auto' = 'auto';

    @Input()
    public rows: number = 2;

    @Input()
    public set editOnClick(value: boolean) {
        this.editOnClickActive = value;
        this.canEdit = !value;
    }

    @Input()
    public canEdit = true;

    @ViewChild('input', { static: true })
    public inputField!: ElementRef<HTMLInputElement>;

    public editOnClickActive = false;

    public inputFocused = false;

    public nextValue$ = new Subject<unknown>();

    public fcValue = new UntypedFormControl();

    public required: boolean = false;

    public constructor(
        private cd: ChangeDetectorRef,
        @Self() @Optional() private control: NgControl,
    ) {
        if (this.control) {
            this.control.valueAccessor = this;
        }
    }

    @HostListener('click')
    enableInput(): void {
        if (this.editOnClickActive && !this.canEdit) {
            this.canEdit = true;
        }
    }

    @HostListener('focusout')
    setInputFocusOut(): void {
        if (this.editOnClickActive) {
            this.canEdit = false;
            this.nextValue$.next(this.fcValue.value);
        }
    }

    ngAfterViewInit(): void {
        this.control?.control?.valueChanges.pipe(
            startWith(this.control?.control),
            filter((control) => control instanceof AbstractControl),
            debounceTime(50),
            untilDestroyed(this),
        )
            .subscribe((control) => {
                this.required = this.hasRequiredField(control);
                this.cd.markForCheck();
            });
    }

    public focusInput(focused: boolean) {
        this.inputFocused = focused;
    }

    public changeEditMode(canEdit: boolean) {
        this.canEdit = canEdit;
        if (canEdit) {
            this.inputField.nativeElement.focus();
        }
    }

    public hasRequiredField(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }

    writeValue(obj: unknown): void {
        this.fcValue.setValue(obj, { emitEvent: false });
    }

    registerOnChange(fn: (arg: unknown) => void): void {
        this.nextValue$
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                this.onTouched();
                fn(value);
            });

        if (!this.editOnClickActive) {
            this.fcValue.valueChanges
                .pipe(untilDestroyed(this))
                .subscribe((value) => this.nextValue$.next(value));
        }
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    private onTouched: () => void = () => {};
}
