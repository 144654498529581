import {
    DOCUMENT,
    Location,
} from '@angular/common';
import {
    inject,
    Inject,
    Injectable,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import {
    of,
    switchMap,
    tap,
} from 'rxjs';

import { UNLOCKED_FEATURE_OPERATION_ID } from '~/app/core/constants/operation-resume-unlocked-feature.constants';
import { ApplicationFacade } from '~/app/core/state/application/application-facade/application.facade';
import { OperationType } from '~/app/shared/enums/operation-type.enum';
import { RESUME_FEATURES } from '~/app/shared/enums/resume-features.enum';
import { ModalService } from '~/app/shared/services/modal/modal.service';
import { ResumeUnlockedFeature } from '~/app/shared/types/operations/operation-resume-unlocked-feature.type';
import { ResumeFeatureContext } from '~/app/shared/types/resume-feature-context.type';

import {
    ModalOnboardingContactSalesComponent,
    ModalOnboardingContactSalesInput,
} from '../../components/modal-onboarding-contact-sales/modal-onboarding-contact-sales.component';
import { ModalOnboardingSubscribeToFreeTrialComponent } from '../../components/modal-onboarding-subscribe-to-free-trial/modal-onboarding-subscribe-to-free-trial.component';
import { ModalOnboardingTrialWelcomeComponent } from '../../components/modal-onboarding-trial-welcome/modal-onboarding-trial-welcome.component';
import {
    ModalLockedFeatureCallbackAction,
    ModalLockedFeatureComponent,
    ModalLockedFeatureInputArgs,
    ModalLockedFeatureMode,
} from '../../presentation/modal-locked-feature/modal-locked-feature.component';

export const FEATURE_TYPES_WITH_ALERT = [RESUME_FEATURES.LAUNCH_SIMULATION_HISTORICAL];
@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ResumeFeatureService {
    private applicationFacade = inject(ApplicationFacade);

    constructor(
        private location: Location,
        @Inject(DOCUMENT) private document: Document,
        private modal: ModalService,
        private router: Router,

    ) { }

    public openLockedFeatureModal(mode: ModalLockedFeatureMode, featureType: RESUME_FEATURES, extraInfoKey: string | null) {
        const context = this.getFeatureContextByResumeFeatureType(featureType, extraInfoKey);
        return this.openLockedFeatureModalByContext(mode, true, context);
    }

    public getFeatureContextByResumeFeatureType(featureType: RESUME_FEATURES, extraInfoKey: string | null = null): ResumeFeatureContext {
        return {
            featureLocation: this.location.path(),
            featureNameKey: `authorisation.feature.${featureType}.name`.toLowerCase(),
            featureDescriptionKey: `authorisation.feature.${featureType}.description`.toLowerCase(),
            ...(extraInfoKey ? {
                featureExtraInfo: extraInfoKey,
            } : {}),
        };
    }

    public signUp(context?: ResumeFeatureContext) {
        if (context) {
            this.applicationFacade.addOperation<ResumeUnlockedFeature>(
                UNLOCKED_FEATURE_OPERATION_ID,
                {
                    type: OperationType.RESUME_UNLOCKED_FEATURE,
                    data: {
                        name: context.featureNameKey,
                    },
                    returnUrl: context.featureLocation,
                },
            );
        }
        void this.router.navigateByUrl('auth/signup');
    }

    public signIn() {
        void this.router.navigateByUrl('/auth/login');
    }

    public freeTrial() {
        const modalInstance = this.modal.open<boolean, unknown>(
            ModalOnboardingSubscribeToFreeTrialComponent,
            {},
        );

        modalInstance.afterClosed$
            .pipe(
                switchMap((value) => {
                    if (!value) {
                        return of(false);
                    }

                    return this.modal.open<unknown, unknown>(
                        ModalOnboardingTrialWelcomeComponent, {},
                    ).afterClosed$.pipe(
                        tap(() => {
                            this.document.defaultView?.location.reload();
                        }),
                    );
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    public contactSales(feature?: string) {
        const modalInstance = this.modal.open<unknown, ModalOnboardingContactSalesInput>(
            ModalOnboardingContactSalesComponent,
            {
                feature,
            },
        );

        modalInstance.afterClosed$
            .pipe(
                untilDestroyed(this),
            )
            .subscribe();
    }

    public openLockedFeatureModalByContext(mode: ModalLockedFeatureMode, withContext: boolean, resumeFeatureContext?: ResumeFeatureContext) {
        const modalInstance = this.modal.open<ModalLockedFeatureCallbackAction, ModalLockedFeatureInputArgs>(
            ModalLockedFeatureComponent,
            {
                context: resumeFeatureContext,
                mode,
                withContext,
            },
            {
                padding: withContext,
                hasBorder: true,
                isOutsideClickCloseModal: true,
            },
        );

        modalInstance.afterClosed$
            .pipe(
                untilDestroyed(this),
            )
            .subscribe((value: ModalLockedFeatureCallbackAction | undefined) => {
                if (value) {
                    switch (value) {
                        case 'SIGN_IN':
                            this.signIn();
                            break;
                        case 'SIGN_UP':
                            this.signUp(resumeFeatureContext);
                            break;
                        case 'UPGRADE_FREE_TRIAL':
                            this.freeTrial();
                            break;
                        case 'UPGRADE_PLAN':
                            this.contactSales(resumeFeatureContext?.featureNameKey);
                            break;
                        default:
                    }
                }
            });

        return modalInstance;
    }
}
