<div class="modal-onboarding-contact-form__header">
    <eb-label
        size="xl"
        textAlign="center"
        weight="bold"
    >
        {{ title }}
    </eb-label>
    @if (subtitle) {
        <eb-label
            size="lg"
            textAlign="center"
        >
            {{ subtitle }}
        </eb-label>
    }
</div>

<div class="modal-onboarding-contact-form__subheader">
    {{ 'authorisation.contact_form.please_answer_form' | transloco }}
</div>

<form
    class="modal-onboarding-contact-form__form"
    [formGroup]="form"
    (ngSubmit)="submitForm()"
>
    <div class="modal-onboarding-contact-form__fields">
        <div class="modal-onboarding-contact-form__field">
            <eb-material-input-select
                [label]="'authorisation.contact_form.your_role_label' | transloco"
                [placeholder]="'authorisation.contact_form.your_role_placeholder' | transloco"
                formControlName="jobId"
                labelPosition="top"
            >
                @for (job of jobs; track job) {
                    <eb-material-input-select-option
                        [label]="job.name"
                        [value]="job.id"
                    />
                }
            </eb-material-input-select>
        </div>
        <div class="modal-onboarding-contact-form__field">
            <eb-material-input-select
                [label]="'authorisation.contact_form.usage_label' | transloco"
                [placeholder]="'authorisation.contact_form.usage_placeholder' | transloco"
                formControlName="useCaseId"
                labelPosition="top"
            >
                @for (useCase of useCases; track useCase) {
                    <eb-material-input-select-option
                        [label]="useCase.name"
                        [value]="useCase.id"
                    />
                }
            </eb-material-input-select>
        </div>
        <div class="modal-onboarding-contact-form__field">
            <eb-material-input-textarea
                [label]="'authorisation.contact_form.message_label' | transloco"
                [placeholder]="'authorisation.contact_form.message_placeholder' | transloco"
                [rows]="3"
                formControlName="description"
                labelPosition="top"
            />
        </div>

        <div class="modal-onboarding-contact-form__field">
            <eb-material-input-phone-number
                [label]="'authorisation.contact_form.phone_label' | transloco"
                [placeholder]="'authorisation.contact_form.phone_placeholder' | transloco"
                defaultCountry="FR"
                formControlName="phone"
            />
        </div>

        <div class="modal-onboarding-contact-form__field modal-onboarding-contact-form__field--checkbox">
            <eb-checkbox-input
                class="modal-onboarding-contact-form__field-checkbox-allow-phone"
                formControlName="allowSalesRecall"
            >
                <span [innerHTML]="'authorisation.contact_form.sales_recall' | transloco"></span>
            </eb-checkbox-input>
        </div>
    </div>
    <div class="modal-onboarding-contact-form__buttons">
        <button
            eb-button
            type="submit"
            [disabled]="form.invalid"
        >
            {{ 'common.confirm' | transloco }}
        </button>
    </div>
</form>
