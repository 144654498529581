@if (this.ref.data.withContext) {
    <div class="d-flex flex-column gap-2">
        <eb-h2 class="d-flex align-items-center justify-content-center my-3">
            <eb-label
                size="xl"
                weight="bold"
            >
                {{ this.ref.data.context?.featureNameKey ?? 'authorisation.feature.default.name' | transloco }}
            </eb-label>
        </eb-h2>
        @if (this.ref.data.context?.featureExtraInfo) {
            <eb-alert
                [type]="'info'"
                size="sm"
                weight="normal"
            >
                {{ this.ref.data.context?.featureExtraInfo | transloco }}
            </eb-alert>
        }
        <div class="d-flex justify-content-center align-items-center">
            <eb-label
                class="modal-locked-feature__description"
                size="lg"
            >
                {{ this.ref.data.context?.featureDescriptionKey ?? 'authorisation.feature.default.description' | transloco }}
            </eb-label>
        </div>
    </div>
}
<div
    class="modal-locked-feature__background"
    [class.modal-locked-feature__background--no-context]="!this.ref.data.withContext"
>
    <div class="d-flex gap-4 align-items-center mx-4 mb-3">
        <div class="modal-locked-feature__try-freemium-icon">
            @if (ref.data.mode === ModalLockedFeatureMode.UPGRADE_TO_FREEMIUM) {
                <eb-icon
                    class="w-100"
                    icon="ui/common/gift"
                />
            } @else {
                <eb-icon
                    class="w-100"
                    icon="ui/common/illustration-migrate-premium"
                />
            }
        </div>

        <div class="d-flex gap-1 flex-wrap-wrap">
            @if (ref.data.mode === ModalLockedFeatureMode.UPGRADE_TO_FREEMIUM) {
                <eb-label
                    [innerHTML]="'authorisation.presentation_freemium' | transloco"
                    color="black"
                    size="lg"
                    textTransform="inherit"
                />
            }

            @if (ref.data.mode === ModalLockedFeatureMode.UPGRADE_TO_FREE_TRIAL || ref.data.mode === ModalLockedFeatureMode.UPGRADE_TO_FREE_TRIAL_OR_PLAN) {
                <eb-label
                    [innerHTML]="'authorisation.presentation_freetrial' | transloco"
                    color="black"
                    size="lg"
                    textTransform="inherit"
                />
            }

            @if (ref.data.mode === ModalLockedFeatureMode.UPGRADE_PLAN) {
                <eb-label
                    [innerHTML]="'authorisation.presentation_premium' | transloco"
                    color="black"
                    size="lg"
                    textTransform="inherit"
                />
            }
        </div>
    </div>
    <div
        class="d-flex justify-content-center align-items-center gap-3"
        [class.flex-column]="ref.data.mode !== ModalLockedFeatureMode.UPGRADE_TO_FREE_TRIAL_OR_PLAN"
    >
        @if (ref.data.mode === ModalLockedFeatureMode.UPGRADE_TO_FREEMIUM) {
            <eb-button-with-icon-alt
                (buttonClick)="signUp()"
                icon="ui/action/symbols/start"
            >
                {{ 'authorisation.free_version' | transloco }}
            </eb-button-with-icon-alt>
        }
        @if (ref.data.mode === ModalLockedFeatureMode.UPGRADE_TO_FREE_TRIAL || ref.data.mode === ModalLockedFeatureMode.UPGRADE_TO_FREE_TRIAL_OR_PLAN) {
            <eb-button-with-icon-alt
                (buttonClick)="freeTrial()"
                icon="ui/action/symbols/start"
            >
                {{
                    (ref.data.mode === ModalLockedFeatureMode.UPGRADE_TO_FREE_TRIAL_OR_PLAN ? 'authorisation.upgrade_to_free_trial_short' : 'authorisation.upgrade_to_free_trial')
                        | transloco
                }}
            </eb-button-with-icon-alt>
        }
        @if (ref.data.mode === ModalLockedFeatureMode.UPGRADE_PLAN || ref.data.mode === ModalLockedFeatureMode.UPGRADE_TO_FREE_TRIAL_OR_PLAN) {
            <eb-button-with-icon-alt
                (buttonClick)="upgradePlan()"
                icon="ui/action/symbols/start"
            >
                {{ 'authorisation.upgrade_plan' | transloco }}
            </eb-button-with-icon-alt>
        }

        @if (ref.data.mode === ModalLockedFeatureMode.UPGRADE_TO_FREEMIUM) {
            <div class="d-flex gap-1 justify-content-center align-items-center flex-wrap-wrap">
                <eb-label size="lg">
                    {{ 'login.you_have_account' | transloco }}
                </eb-label>
                <eb-button-link
                    class="d-flex align-self-center align-baseline"
                    (buttonClick)="signIn()"
                    size="lg"
                    textTransform="inherit"
                >
                    <eb-label
                        size="lg"
                        weight="bold"
                    >
                        {{ 'login.submit_link' | transloco }}
                    </eb-label>
                </eb-button-link>
            </div>
        }
    </div>
</div>
